<template>
  <v-container v-if="details !== null">
    <v-snackbar id="snackbar" v-model="mainSnackbar" :color="color" top :timeout="5000">
      <v-row>
        {{ text }}
        <v-spacer></v-spacer>
        <v-btn
          dark
          icon
          @click="mainSnackbar = false"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-snackbar>
    <h3 class="secondary--text mt-5">Booking Details</h3>
    <hr class="hor">
    <v-card class="pa-5 mt-5">
      <v-row class="">
        <v-simple-table dense style="width: 100%;">
          <template v-slot:default>
            <thead>
                <tr>
                  <th class="text-left">PNR</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Time</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Price</th>
                  <th class="text-left" v-if="details.status.status === 'Initial'">Issuing</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ details.pnr_id }}</td>
                    <td>{{ formatDateTime(details.created_at.substr(0,10)) }}</td>
                    <td>{{ details.created_at.substr(11,5) }}</td>
                    <td>{{ details.status.status }}</td>
                    <td>${{ details.price }}</td>
                    <td v-if="details.status.status === 'Initial'">
                      <v-btn v-if="$store.state.user.role === 'subAgent' || $store.state.user.role === 'ats_subAgent'" @click="issueRequestDialog = true" small elevation="0" tile color="blueDark white--text" class="mt-1">
                        request to issue
                      </v-btn>
                      <v-btn :loading="loadCancelBtn" tile small elevation="0" class="mt-1 ml-1" color="error" @click="cancelFlight">Cancel</v-btn>
                    </td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-card>

    <h3 class="secondary--text mt-5">Contact Person Details</h3>
    <hr class="hor">
    <v-card class="pa-5 mt-5">
      <v-row class="">
        <v-simple-table dense style="width: 100%;">
          <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Phone</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ details.contact_person }}</td>
                    <td>{{ details.contact_email }}</td>
                    <td>{{ details.contact_phone }}</td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-card>

    <h3 class="secondary--text mt-5">Passengers Details</h3>
    <hr class="hor">
    <v-card class="pa-5 mt-5">
      <v-row class="">
        <v-simple-table dense style="width: 100%;">
            <template v-slot:default>
              <thead>
                  <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Birth Date</th>
                      <th class="text-left">Passport</th>
                      <th class="text-left">Passport Expire Date</th>
                      <th class="text-left">Issue Country</th>
                      <th class="text-left">Ticket</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="passenger in details.passenger_details" :key="passenger.id">
                      <td>{{ passenger.passenger_name }}</td>
                      <td>{{ formatDateTime(passenger.date_of_birth) }}</td>
                      <td v-if="passenger.passport_number">{{ passenger.passport_number }}</td>
                      <td v-else>--</td>
                      <td v-if="passenger.passport_expire_date">{{ formatDateTime(passenger.passport_expire_date) }}</td>
                      <td v-else>--</td>
                      <td v-if="passenger.passport_issue_country">{{ passenger.passport_issue_country }}</td>
                      <td v-else>--</td>
                      <td>{{ passenger.ticket_number ? passenger.ticket_number : 'Not ticketed yet' }}</td>
                      <td class="text-left" v-if="!passenger.passport_number || !passenger.passport_expire_date || !passenger.passport_issue_country">
                        <v-btn text x-small color="blue" @click="openPassportForm(passenger)">manage passport</v-btn>
                      </td>
                  </tr>
              </tbody>
            </template>
        </v-simple-table>
      </v-row>
    </v-card>

    <v-dialog v-model="passportFormDialog">
      <v-card v-if="passengerInfo" class="pa-5">
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-card-title>Update passport informaton</v-card-title>
        <v-form v-model="validPassport" ref="passportForm">
          <v-text-field
          v-model="passengerInfo.passport_number"
          label="Passport Number"
          prepend-inner-icon="mdi-passport"
          outlined
          :rules="[
            v => !!v || 'Passport number is required',
            v => v && v.length <= 15 || 'Maximum number of characters is 15',
            v => v && v.length >= 5 || 'Minimum number of characters is 5'
          ]"
          persistent-hint
          color="blue"
          id="passenger-passport-num"
          ></v-text-field>

          <date-picker
          v-model="passengerInfo.passport_expire_date"
          clearable
          editable
          valueType="YYYY-MM-DD"
          placeholder="Passport Expire Date:Optional"
          :default-value="passportMinExpireDate"
          style="width: 100%; height: 56px !important;"
          :disabled-date="minExpireDateRange"
          format="M/D/YYYY"
          hint="MM/DD/YYYY'"
          append-to-body
          :input-attr="{id: 'passportExpiry'}"
          @focus="changeInputColor('passportExpiry', '#2196F3')"
          @blur="changeInputColor('passportExpiry', '#9e9e9e')"
          @input-error="changeInputColor('passportExpiry', 'red')"
          id="passenger-passportExpiry"
          >
          </date-picker>
          <span class="caption grey--text">Hint: MM/DD/YYYY</span>

          <v-autocomplete
          v-model="passengerInfo.passport_issue_country"
          :items="countries"
          item-text="name"
          item-value="code"
          placeholder="Issuing Country:Optioanl"
          persistent-hint
          name="issue-country-for-passports"
          required
          outlined
          prepend-inner-icon="mdi-flag"
          hide-no-data
          color="blue"
          autocomplete="off"
          :menu-props="{ auto: true, maxWidth: 200, overflowY: true }"
          id="passenger-passport-issue-country"
          :rules="[v => !!v || 'This field is required']"
          ></v-autocomplete>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blueDark" dark large tile elevation="0" @click="savePassengerPassportInfo" :loading="saveLoad">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog  max-width="500" v-model="issueRequestDialog">
      <v-card max-width="500" v-if="user.subAgent" class="pa-5">
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
          <v-row>
            {{ text }}
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <v-card-title class="px-0">Request Issue</v-card-title>
        <v-select :items="user.subAgent.agents_emails" class="mb-5" hide-details v-model="selectedEmail" outlined label="Choose agent to issue"></v-select>
        <v-text-field v-model="emailToAdd" :rules="[v => (/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || v === '') || 'E-mail must be valid']" outlined label="Add Email" hint="Add email to emails list" persistent-hint append-icon="mdi-plus-circle" @click:append="addEmail"  @keyup.enter="addEmail"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile elevation="0" color="blueDark white--text" :disabled="!selectedEmail" @click="requestIssue" :loading="loadRequestBtn">Request</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="details.payment_type === 'Cash'" class="mt-5">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="secondary--text text-h6">
            Add credit card
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form v-model="validPayment" ref="paymentForm">
              <address-card />
              <v-card class="pa-5 mt-5" outlined>
                <v-card-title>Credit Card Informatio</v-card-title>
                <credit @holder="setCardHolder" @card="setCardData" @expire="setCardExpiry" @cvv="setCVV" />
              </v-card>
              <v-btn color="blueDark" dark large tile elevation="0" class="mt-3 px-15" right @click="saveCardData" :loading="saveCardLoad">Save</v-btn>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <h3 class="secondary--text mt-5">Flight Segments</h3>
    <hr class="hor">
    <v-row class="" v-for="(segment, i) in details.flight_segments" :key="segment.id">
      <v-card class="px-5 ma-3 mt-5" width="100%">
        <h3 class="secondary--text my-2">Segment {{i + 1}}</h3>
        <v-simple-table dense style="width: 100%;">
          <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Airline</th>
                    <th class="text-left">Flight NO.</th>
                    <th class="text-left">Duration</th>
                    <th class="text-left">From</th>
                    <th class="text-left">To</th>
                    <th class="text-left">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ segment.airline }}</td>
                    <td>{{ segment.flight_number }}</td>
                    <td>{{ segment.flight_duration }}h</td>
                    <td>
                      {{ segment.departure_location }}
                      <br>
                      {{formatDateTime(segment.departure_date)}}, {{segment.departure_time}}
                    </td>
                    <td>
                      {{ segment.arrival_location }}
                      <br>
                      {{formatDateTime(segment.arrival_date)}}, {{segment.arrival_time}}
                    </td>
                    <td>
                      {{segment.status.name}}
                    </td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { countries } from '../contants'
import { headers, mangeAgentEmails, issueRequest, cancelFlight, addCreditCard, updatePassportInfo } from '../links'
import credit from '../components/credit'
import addressCard from '../components/address info.vue'
import { mapState } from 'vuex'

export default {
  props: ['details'],
  components: {
    credit,
    addressCard
  },
  data () {
    return {
      passengerInfo: null,
      passportFormDialog: false,
      validPassport: true,
      saveLoad: false,
      saveCardLoad: false,
      creditInfo: {
        creditCardNumber: '',
        creditCardExpireDate: '',
        creditCardType: '',
        cvv: '',
        creditCardHolderName: ''
      },
      validPayment: true,
      snackbar: false,
      mainSnackbar: false,
      color: '',
      text: '',
      loadCancelBtn: false,
      loadRequestBtn: false,
      issueRequestDialog: false,
      selectedEmail: '',
      emailToAdd: ''
    }
  },
  computed: {
    countries () {
      return countries
    },
    ...mapState(['user'])
  },
  methods: {
    formatDateTime (date) {
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${day} ${months[month - 1]} ${year}`
    },
    openPassportForm (passenger) {
      this.passengerInfo = JSON.parse(JSON.stringify(passenger))
      this.passportFormDialog = true
    },
    passportMinExpireDate () {
      const lastFlightSegment = this.details.flight_segments[this.details.flight_segments.length - 1]
      console.log(lastFlightSegment)
      const lastArrivalDate = lastFlightSegment.arrival_date
      const today = new Date(lastArrivalDate)
      today.setMonth(today.getMonth() + 6)
      return today
    },
    minExpireDateRange (date) {
      return date < new Date(this.passportMinExpireDate())
    },
    changeInputColor (id, color) {
      document.getElementById(id).style.borderColor = color
    },
    setCardData (number, type) {
      this.creditInfo.creditCardNumber = number
      switch (type) {
        case 'visa':
          this.creditInfo.creditCardType = 'VI'
          break
        case 'mastercard':
          this.creditInfo.creditCardType = 'CA'
          break
        case 'american-express':
          this.creditInfo.creditCardType = 'AX'
          break
        case 'discover':
          this.creditInfo.creditCardType = 'DS'
          break
        case 'diners-club':
          this.creditInfo.creditCardType = 'DN'
          break
        default:
          this.creditInfo.creditCardType = type
          break
      }
    },
    setCardExpiry (date) {
      this.creditInfo.creditCardExpireDate = date
    },
    setCVV (cvv) {
      this.creditInfo.cvv = cvv
    },
    setCardHolder (name) {
      this.creditInfo.creditCardHolderName = name
    },
    savePassengerPassportInfo () {
      this.$refs.passportForm.validate()
      if (this.validPassport && this.passengerInfo.passport_expire_date) {
        this.saveLoad = true
        const body = {
          reference_number: this.details.booking_reference,
          passenger_details: [this.passengerInfo]
        }
        this.$http.post(updatePassportInfo, body, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          if (response.data.status) {
            this.details.passenger_details[this.details.passenger_details.findIndex(el => el.id === this.passengerInfo.id)].passport_number = this.passengerInfo.passport_number
            this.details.passenger_details[this.details.passenger_details.findIndex(el => el.id === this.passengerInfo.id)].passport_issue_country = this.passengerInfo.passport_issue_country
            this.details.passenger_details[this.details.passenger_details.findIndex(el => el.id === this.passengerInfo.id)].passport_expire_date = this.passengerInfo.passport_expire_date
            this.snackbar = true
            this.color = 'success'
            this.text = 'Passport information updated successfully'
          } else {
            this.snackbar = true
            this.color = 'error'
            this.text = response.data.message
          }
        }).catch(err => {
          this.snackbar = true
          this.color = 'error'
          this.text = err.data.message
        }).finally(() => {
          this.saveLoad = false
          this.passportFormDialog = false
          this.$refs.passportForm.reset()
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    },
    saveCardData () {
      this.$refs.paymentForm.validate()
      if (this.validPayment) {
        this.$store.dispatch('setWrittenAddress', document.getElementById('card-address').value)
        this.saveCardLoad = true
        var body = {
          reference_number: this.details.booking_reference,
          creditCardNumber: this.creditInfo.creditCardNumber,
          creditCardExpireDate: this.creditInfo.creditCardExpireDate,
          creditCardType: this.creditInfo.creditCardType,
          creditCardHolderName: this.creditInfo.creditCardHolderName,
          cvv: this.creditInfo.cvv,
          zipCode: this.$store.state.zipCode,
          address: this.$store.state.writtenAddress,
          streetLine: this.streetLine,
          city: this.$store.state.writtenCity,
          state: this.$store.state.writtenState,
          countryISO: this.$store.state.writtenCountry,
          kountSessionId: sessionStorage.getItem('kountSessionId')
        }

        this.$http.post(addCreditCard, body, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          console.log(response.data)
          if (response.data.status) {
            this.details.payment_type = 'Credit Card'
            this.mainSnackbar = true
            this.color = 'success'
            this.text = 'Passport information updated successfully'
            this.$emit('updateBookings')
          } else {
            this.mainSnackbar = true
            this.color = 'error'
            this.text = response.data.message
          }
        }).catch(err => {
          this.mainSnackbar = true
          this.color = 'error'
          this.text = err.data.message
        }).finally(() => {
          this.saveCardLoad = false
          // this.$refs.paymentForm.reset()
        })
      } else {
        this.mainSnackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    },
    cancelFlight () {
      this.loadCancelBtn = true
      this.$http.post(cancelFlight, { pnr: this.details.pnr_id }, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status) {
          this.mainSnackbar = true
          this.color = 'success'
          this.text = 'Flight cancelled'
          this.details.status.status = 'Cancelled'
        } else {
          this.mainSnackbar = true
          this.color = 'error'
          this.text = response.body.message
        }
      }).catch(err => {
        this.disableCancelBtn = false
        this.mainSnackbar = true
        this.color = 'error'
        this.text = err.body.message
      }).finally(() => {
        this.loadCancelBtn = false
      })
    },
    addEmail () {
      if (this.emailToAdd) {
        const emails = this.user.subAgent.agents_emails
        emails.push(this.emailToAdd)
        this.$http.post(mangeAgentEmails, { emails: emails }, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          this.snackbar = true
          this.color = 'success'
          this.text = 'Emails Updated'
          this.user.subAgent.agents_emails = response.body.data
        }).catch(err => {
          this.snackbar = true
          this.color = 'error'
          this.text = err.body.message
        }).finally(() => {
          this.emailToAdd = ''
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please add email at first'
      }
    },
    requestIssue () {
      if (this.selectedEmail) {
        const payload = {
          agent_email: this.selectedEmail,
          booking_reference: this.details.booking_reference
        }
        this.loadRequestBtn = true
        this.$http.post(issueRequest, payload, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          this.mainSnackbar = true
          this.color = 'success'
          this.text = 'Request Sent'
        }).catch(err => {
          this.snackbar = true
          this.color = 'error'
          this.text = err.body.message
        }).finally(() => {
          this.loadRequestBtn = false
          this.issueRequestDialog = false
        })
      }
    }
  }
}
</script>

<style>
  .hor {
    border: none;
    height: 2px;
    width: 169px;
    background-color: #DF731C;
    margin: 15px 0;
  }
  .mx-input {
    width: 100%;
    height: 56px !important;
    border-color: #9e9e9e;
    margin-bottom: 20px !important;
  }
</style>
